import { ApiService, GenericService } from 'core/services'
import {
  ParcelInfo,
  PackageType,
  ParcelRoute,
  PackageCost,
  ParcelEadRad,
  CalculateMultiplePromo,
  CalculateMultiplePromoResponse
} from './types'

class ParcelService {
  public static instance: ParcelService

  constructor() {
    if (ParcelService.instance) {
      return ParcelService.instance
    }
    ParcelService.instance = this
  }

  getPackageTypes() {
    return GenericService.list<PackageType[]>('packageTypes', { where: `shipping_type='parcel' AND status != 3` })
  }

  getTracking(code: string) {
    return ApiService.get<ParcelInfo>(`parcels/trackingCode/${code}`)
  }

  getRoutes(origin: number, destination: number, date: string) {
    return ApiService.get<ParcelRoute[]>(`parcelsPackages/routes/${origin}/${destination}/${date}`)
  }

  getCost(packages: PackageCost[]) {
    return ApiService.post<{ packages: PackageCost[] }, { packages: PackageCost[] }>(`parcelsPackages/cost`, { packages })
  }

  calculateMultiplePromo(body: CalculateMultiplePromo) {
    return ApiService.post<CalculateMultiplePromoResponse, CalculateMultiplePromo>('promos/calculateMultiplePromo', body)
  }

  getEadRadService() {
    return ApiService.get<ParcelEadRad>(`ead_rad/get_service_rad_ead_site`)
  }
}

const instance = new ParcelService()

Object.freeze(instance)

export default instance
